import { graphql } from 'gatsby'
import { EditorProvider } from '@tiptap/react'
import { Header, Footer } from '@/components'
import Table from '@tiptap/extension-table'
import TableCell from '@tiptap/extension-table-cell'
import TableHeader from '@tiptap/extension-table-header'
import TableRow from '@tiptap/extension-table-row'
import TextAlign from '@tiptap/extension-text-align'
import Link from '@tiptap/extension-link'
import StarterKit from '@tiptap/starter-kit'
import { Box } from '@mui/material'
import React from 'react'

const CustomTableCell = TableCell.extend({
  addAttributes() {
    return {
      ...this.parent?.(),
      backgroundColor: {
        default: null,
        parseHTML: (element) => element.getAttribute('data-background-color'),
        renderHTML: (attributes) => {
          return {
            'data-background-color': attributes.backgroundColor,
            style: `background-color: ${attributes.backgroundColor}`,
          }
        },
      },
      textAlign: {
        default: null,
        parseHTML: (element) => element.getAttribute('text-align'),
        renderHTML: (attributes) => {
          return {
            'text-align': attributes.textAlign,
            style: `text-align: ${attributes.textAlign}`,
          }
        },
      },
    }
  },
})

const extensions = [
  StarterKit.configure({
    bulletList: {
      keepMarks: true,
      keepAttributes: false,
    },
    orderedList: {
      keepMarks: true,
      keepAttributes: false,
    },
  }),
  TextAlign.configure({
    types: ['heading', 'paragraph'],
  }),
  Link.configure({
    HTMLAttributes: {
      target: '_blank',
      rel: 'noopener noreferrer',
    },
  }),
  Table,
  TableRow,
  TableHeader,
  CustomTableCell,
]

const content = `
<h2 style="text-align: center">Legal Notice</h2>
    <p>Company Name: iMile Logistics Co., Ltd.</p>
    <p>Company Number:15652374</p>
    <p>Registered Address:</p>
    <p>C/O MISHCON DE REYA AFRICA HOUSE</p>
    <p>70 KINGSWAY</p>
    <p>LONDON</p>
    <p>GREATER LONDON</p>
    <p>UNITED KINGDOM WC2B 6AH</p>
    <p>Contact Information:</p>
`
const PrivacyPolicyBrazil = (props) => {
  return (
    <>
      <Header
        location={props.location}
        headerLocale={JSON.parse(props.data.headerLocale.data)}
        footerLocale={JSON.parse(props.data.footerLocale.data)}
        languageLocale={JSON.parse(props.data.languageLocale.data)}
      />
      <Box pt={15} display='flex' alignItems='center' justifyContent='center'>
        <EditorProvider extensions={extensions} content={content} editable={false}></EditorProvider>
      </Box>
      <Footer
        footerLocale={JSON.parse(props.data.footerLocale.data)}
        languageLocale={JSON.parse(props.data.languageLocale.data)}
      />
    </>
  )
}

export default PrivacyPolicyBrazil

export const query = graphql`
  query($language: String!) {
    locales: allLocale(
      filter: { ns: { in: ["PrivacyPolicyBrazil"] }, language: { eq: $language } }
    ) {
      edges {
        node {
          ns
          data
        }
      }
    }
    commonLocale: locale(ns: { eq: "common" }, language: { eq: $language }) {
      data
    }
    headerLocale: locale(ns: { eq: "header" }, language: { eq: $language }) {
      data
    }
    footerLocale: locale(ns: { eq: "footer" }, language: { eq: $language }) {
      data
    }
    languageLocale: locale(ns: { eq: "language" }, language: { eq: $language }) {
      data
    }
    seoLocale: locale(ns: { eq: "seo" }, language: { eq: $language }) {
      data
    }
  }
`
